import React from "react"
import SEO from "../components/seo"
import splashImage from "../../content/assets/landing_splash_boxes.jpg"
import logo from "../../content/assets/roseandjasper_logo.svg"
import imgDivineBliss from "../../content/assets/bliss.jpg"
import imgMyTruth from "../../content/assets/truth.jpg"
import imgInnerPower from "../../content/assets/power.jpg"
import rocks from "../../content/assets/rocks.jpg"
import booklet from "../../content/assets/booklet.jpg"
import gift from "../../content/assets/gift.jpg"
import love from "../../content/assets/love.jpg"
import footerImage from "../../content/assets/love-footer.jpg"

import Product from "../components/product"

export default function Home() {
  return (
    <div>
      <SEO title="Essential for the modern alchemist" />
      <div className="min-h-screen min-w-full relative flex px-4 py-10">
        <div className="container mx-auto md:max-w-prose flex flex-col">
          <div className="flex justify-center mb-12">
            <img className="w-auto h-12" src={logo} alt="" />
          </div>
          <div className="flex-grow flex flex-col justify-center mb-8">
            <div className="text-white text-center mt-8 mb-16">
              <h1 className="splash-header">
                Essentials for the modern alchemist.
              </h1>
              <p className="text-lg md:text-1xl">
                Subscribe and get 30% off your first order
              </p>
            </div>
            <form
              action="https://roseandjasper.us10.list-manage.com/subscribe/post?u=9f3eac442c283af4ec600969d&amp;id=c15521be57"
              method="post"
              target="_blank"
              noValidate
              className="flex flex-col md:px-12"
            >
              <label
                className="text-white font-bold mb-2 text-center"
                htmlFor="mce-EMAIL"
              >
                Email address
              </label>
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                required
                style={{ maxWidth: "400px" }}
                className="w-full block mx-auto px-4 rounded-full border-gray-300 focus:ring-pink-500 focus:border-pink-500 mb-4"
              ></input>
              <button
                className="self-center flex items-center bg-pink-500 hover:bg-pink-600 rounded-full text-white py-3 px-7"
                type="submit"
              >
                Subscribe
              </button>
            </form>
          </div>

          <div className="bg-black absolute inset-0 h-full w-full flex -z-1">
            <img
              className="object-cover opacity-50 w-full"
              src={splashImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}
